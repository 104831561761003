import { useV3 } from '../experiments/useV3';
import {
  StepType,
  type Step,
} from '@wix/ambassador-online-programs-v3-step/types';
import { useChallengeData } from '../contexts/storage-contexts/Challenge';

export function useVideoStepData(challengeStep: Step) {
  const isV3 = useV3();
  const { challengeData } = useChallengeData();
  const stepType = isV3 && challengeStep?.stepType;
  const isVideo = isV3 && stepType === StepType.VIDEO;
  const videoOptions = isVideo && challengeStep?.videoOptions;
  const videoStepDuration = isVideo
    ? challengeStep?.videoOptions.durationInMilliseconds
    : null;
  const videoStepsSettings =
    challengeData?.challenge?.settings?.videoStepsSettings;
  const autoNavigate = videoStepsSettings?.autoNavigate;
  const requiredCompletionPercentage =
    videoStepsSettings?.requiredCompletionPercentage;

  return {
    isV3,
    stepType,
    isVideo,
    videoOptions,
    videoStepDuration,
    autoNavigate,
    requiredCompletionPercentage,
  };
}
